import React from 'react';
import styled from 'styled-components';
import logoSvg from '../assets/images/logo-schoonheidsalonlineke.svg';

const LogoStyles = styled.div`
  width: 8rem;
  height: 3rem;
  background: url(${logoSvg});
  background-size: 8rem 3rem;
  background-repeat: no-repeat;
`;

export default function Logo() {
  return <LogoStyles />;
}
