import React from 'react';
import styled from 'styled-components';
import GlobalStyles from '../../styles/GlobalStyles';
import Header from './Header';
import Footer from '../../styles/ContactFooter';
import Menu from '../Menu';

const Main = styled.div`
  margin: 0 auto;
  padding-right: var(--spacing-l);
  font-family: var(--font-family);
  font-weight: 300;
  width: 100%;
  
  @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
    padding-right: 0;   
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export default function Layout({ children, uri }) {
  const bodyClassName = !uri
    ? 'page-404'
    : uri.replace('/', '') !== 'home'
    ? 'page'
    : 'home';
  return (
    <div className={bodyClassName}>
      <GlobalStyles />
      <Header uri={uri} />
      <ContentWrapper>
        <Menu uri={uri} />
        <Main>{children}</Main>
      </ContentWrapper>
      <Footer />
    </div>
  );
}
