import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: sticky;
  top: 8rem;
  display: flex;
  flex-direction: row;
  width: 50%;
  padding-left: var(--spacing-l);
  margin-top: 8rem;
  margin-right: 2rem;
  z-index: 1;
  
  @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px)
    and (-webkit-min-device-pixel-ratio: 2) {
    display: none;
  }
  
  /* Portrait */
  @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1) {
    width: 40%;
  }
`;

const MenuWrapper = styled.nav`
  display: flex;
  flex-direction: column;

  .navigationItem {
    font-size: 1.8rem;
    font-family: var(--font-small);
    margin-right: var(--spacing-xl);
    margin-bottom: var(--spacing-l);
    font-weight: 200;
    text-decoration: none;
    color: #bdbdbd;
    transition: 0.6s;
    
    /* Portrait */
    @media only screen 
      and (min-device-width: 768px) 
      and (max-device-width: 1024px) 
      and (orientation: portrait) 
      and (-webkit-min-device-pixel-ratio: 1) {
      font-size: 1.4rem;
    }
  }

  .navigationItem:hover {
    color: #000000;
  }
`;

export default function Menu() {
  const currentClassName = 'navigationItem';
  return (
    <Wrapper>
      <MenuWrapper>
        <a href="#home" className={currentClassName}>
          Home
        </a>
        <a href="#treatments" className={currentClassName}>
          Behandelingen
        </a>
        <a href="#contact" className={currentClassName}>
          Contact
        </a>
      </MenuWrapper>
    </Wrapper>
  );
}
