import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: var(--spacing-l);
  padding-bottom: var(--spacing-xl);
  padding-right: var(--spacing-l);
  padding-left: var(--spacing-l);
  
  @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
    display: none;   
  }
`;

const BlockEmpty = styled.div`
  width: 16rem;
`;

const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 16rem;
  padding-top: 0.28rem;
  font-size: 1.2rem;
  font-family: var(--font-small);
  color: var(--black);

  .alignmentRight {
    text-align: right;

    .smallMailText {
      font-size: 0.7rem;
    }
  }
`;

export default function DefaultHeader() {
  return (
    <Container>
      <BlockEmpty />
      <Block>
        <div>
          <div>RIETLANDEN 28</div>
          <div>6576 EB OOIJ</div>
        </div>
      </Block>
      <Block>
        <div className="alignmentRight">
          <div>MA 09:00 - 15:00</div>
          <div>DI 09:00 - 17:00</div>
          <div>WO 09:00 - 12:00</div>
          <div>DO 09:00 - 17:00</div>
          <div>06 48 17 80 42</div>
          <div className="smallMailText">INFO@SCHOONHEIDSSALONLINEKE.NL</div>
        </div>
      </Block>
    </Container>
  );
}
