import React from 'react';
import DefaultHeader from '../DefaultHeader';

export default function Header(props) {
  const uri = !props.uri ? '404' : props.uri.replace('/', '');

  return (
    <div className={`navigation ${uri}`}>
      <DefaultHeader />
    </div>
  );
}
